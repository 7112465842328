// .custom-toggle-button {
//     width: fit-content;
//     // float: left;
// }

.custom-toggle-button .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.custom-toggle-button .btn {
    border-radius: 0 !important;
}

.custom-toggle-button .btn-check:active+.btn-outline-info,
.custom-toggle-button .btn-check:checked+.btn-outline-info,
.custom-toggle-button .btn-outline-info.active,
.custom-toggle-button .btn-outline-info.dropdown-toggle.show,
.custom-toggle-button .btn-outline-info:active {
    color: #fff;
    background-color: #11cdef;
    border-color: #11cdef;
}

.search-result .toggle-button {
    margin-top: 30px;
    margin-left: 20px;
}
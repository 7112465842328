.flag-container {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;

    .three-dots {
        display: inline-block;
        margin-left: 1px;
        cursor: pointer;
        font-size: 16px;
        visibility: visible;
    }

    .three-dots-hidden {
        visibility: hidden;
    }

    .three-dots:hover {
        color: #f50e0e;
    }

    .flag-icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        text-decoration: none;
    }

    .grey-icon {
        background-color: #B0B0B0;
    }

    .green-icon {
        background-color: #009F92;
    }

    .red-icon {
        background-color: #C00000;
    }

    .blue-icon {
        background-color: #80D4E0;
    }

    .vertical-align td {
        vertical-align: middle !important;
    }

    .disabled {
        opacity: 0.6;
        pointer-events: none; // Makes sure clicks are ignored
        cursor: not-allowed;
    }
}